/*------------------------Globales-------------------------*/
[type="file"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
}
[type="file"] + label {
  width: 200px;
  height:100px;
  background-color:#6C0E60;
  color:white;
  border-radius: 3px;
  border: 2px solid #6C0E60;
  margin: 3rem 1rem; 
  padding: 2.5em 4em;
  text-align: center;
}

[type="file"]:focus + label,
[type="file"] + label:hover {
    background: transparent;
    color: #6C0E60;
    border: 2px solid #6C0E60;
}
  
/*-----------------------Biblioteca-----------------------*/
.carddisplay-box{
    background-color: brown;
    width:100%;
}
.cardbox-shadow{
    overflow: hidden;
width:100px;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
    -webkit-box-shadow: 4px 2px 10px -4px rgba(48, 50, 50, 0.75);
-moz-box-shadow:    4px 2px 10px -4px rgba(48, 50, 50, 0.75);
box-shadow:         4px 2px 10px -4px rgba(48, 50, 50, 0.75);
}

/*-----------------------Landing-----------------------*/
.ant-collapse-header{
    background-color: #6C0E60  !important;
    color:white !important;
    font-family: "Roboto";
    font-weight: 200;
    letter-spacing: 3px;
    padding-bottom: 1% !important;
}

.HeroDiv{
    display: block;
}
.CollapseFECOER{
    display: block;
}
.title-hero{
    font-size:2rem ;
}
.title-hero-span{
    font-size:1rem !important;
    line-height: 10px !important;
}


/*-----------------------Organizaciones---------------------*/
.ant-card-actions li{
    margin:0 !important;
}
.ant-card-actions{
    display: block;
    bottom: 0;
    left: 0;
    position:absolute;
    width:100%;
}
.detailpaper-divider-list{
    border-top: 1px solid;
    margin: 0px;
    padding: 0px;
    height: 20px !important;
}
/**
------------------------------------------------------------------------------------------------
---------------------------------- MEDIA QUERIES ------------------------------------------------
------------------------------------------------------------------------------------------------
*/

@media only screen and (max-width: 600px) {
/*-----------------------Landing-----------------------*/
    .HeroDiv{
        display: none !important;
    }
    .CollapseFECOER{
        margin: 55% 15% 4% 5% !important;
    }
}
@media only screen and (min-width: 601px) {
    /*-----------------------Landing-----------------------*/
    .title-hero{
        position: static;
        display: block;
        padding: 70% 50% 10% 5% !important;
        font-size:2.5rem !important;
    }
    .title-hero-span{
        font-size:0.8rem !important;
        line-height: 10px !important;
    }
    
}
@media only screen and (min-width: 768px) {
    /*-----------------------Landing-----------------------*/
    .title-hero{
        position: static;
        display: block;
        padding: 40% 60% 30% 5% !important;
        font-size:3rem !important;
    }
    .title-hero-span{
        font-size:1rem !important;
        line-height: 10px !important;
    }
    
}

@media only screen and (min-width: 1024px) {
    /*-----------------------Landing-----------------------*/
    .title-hero{
        position: static;
        display: block;
        padding: 30% 60% 30% 5% !important;
        font-size:4rem !important;
    }
    .title-hero-span{
        font-size:1.2rem !important;
        line-height: 10px !important;
    }
    
}

@media only screen and (min-width: 1440px) {
    /*-----------------------Landing-----------------------*/
    .title-hero{
        position: static;
        display: block;
        padding: 20% 60% 50% 10% !important;
        font-size:5rem !important;
    }
    .title-hero-span{
        font-size:1.5rem !important;
        line-height: 10px !important;
    }
    
}
@media only screen and (min-width: 2100px) {
    /*-----------------------Landing-----------------------*/
    .title-hero{
        position: static;
        display: block;
        padding: 15% 60% 55% 10% !important;
        font-size:5rem !important;
    }
    .title-hero-span{
        font-size:1.7rem !important;
        line-height: 10px !important;
    }
    
}

@media only screen and (min-width: 2500px){
    /*-----------------------Landing-----------------------*/
    .title-hero{
        position: static;
        display: block;
        padding: 15% 60% 60% 22% !important;
        font-size:5rem !important;
    }
    .title-hero-span{
        font-size:1.7rem !important;
        line-height: 10px !important;
    }
}