.header-general{
    background-color: #1A041C !important;
    box-shadow: -3px -5px 96px -14px rgba(0,0,0,0.75) !important;
    -webkit-box-shadow: -3px -5px 96px -14px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: -3px -5px 96px -14px rgba(0,0,0,0.75) !important;
    backface-visibility: hidden !important
}
.menu-general{
    margin-top:30px;
    display: block;
    background-color: #1A041C !important;
    text-decoration: none;
    color:white !important;
    text-align: right;
    font-family: "Poppins" !important;
    border:none !important;
}
.home-link{
    color:white !important;
}
.home-link-movil{
    color:#1A041C !important;
}
.submenu-options{
    font-family: "Poppins" !important;
}
.menu-item{
    display: block;
    padding: 0 1% !important;
    color:#6C0E60 !important;
}
.menu-item:hover{
    display: block;
    background-color: white !important;
    text-decoration: none;
    color:#6C0E60 !important;
}
.menu-item :hover{
    display: block;
    background-color: white !important;
    text-decoration: none;
    color:#6C0E60 !important;
}
.menu-item:active{
    display: block;
    background-color: white !important;
    text-decoration: none;
    color:#6C0E60 !important;
}


.menu-item-link{
    display: block;
    padding: 0 1% !important;
    color:#6C0E60 !important;
}
.menu-item-link:hover{
    display: block;
    background-color: white !important;
    text-decoration: none;
    color:#6C0E60 !important;
}
.menu-item-link :hover{
    display: block;
    background-color: white !important;
    text-decoration: none;
    color:#6C0E60 !important;
}
.menu-item-link:active{
    display: block;
    background-color: white !important;
    text-decoration: none;
    color:#6C0E60 !important;
}
.logo_u{
    position: absolute;
    display: block;
    width: 7%;
    max-width: 30%;
}
.logo-layout{
    display: block;
    text-align: left;
    margin:2% 0;
}
@media only screen and (max-width: 600px) {
    
    .menu-general{
        display: block;
        background-color: #1A041C !important;
        text-decoration: none;
        color:white !important;
        text-align: right;
        font-family: "Poppins" !important;
        display: grid; 
        margin-top:30px; 
    }
    .home-link{
        color:#1A041C !important;
    }
    .logo-layout{
        display: block;
        text-align: center;
    }
    .submenu-options{
        width: 100%  !important;
        font-family: "Poppins" !important;
    }
}